.container {
  width: 90%;
  display: flex;
  margin-left: 40px;
  align-items: center;
  gap: 10px;
}

.progressBar {
  flex-grow: 1;
  height: 6px;
  background-color: #e0e0e0;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.navButtons {
  display: flex;
  gap: 10px;
}

.navButton {
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color:#00ff00;
  transition: background-color 0.3s;
}

