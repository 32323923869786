.container {
  width: 100%;
  object-fit: cover;
}

.facilities_container {
  position: relative;
}

.facilities_container img {
  width: 100%;
  height: 535px;
  box-sizing: border-box;
}

.facilities_text_wrapper {
  width: 100%;
  max-width: 1176px;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
}

.facilities_text_container {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.facilities_text_container h3 {
  font-size: 1.25rem;
  font-family: creato_displayextrabold;
  text-transform: uppercase;
  color: #fff; 
}

.facilities_text_container h1 {
  font-size: 4rem;
  text-transform: uppercase;
  line-height: 5rem;
  color: #fff; 
}

@media (max-width: 768px) {
  .facilities_text_container h1 {
    font-size: 2rem;
  }

  .facilities_text_container h3 {
    font-size: 1rem;
  }

  .facilities_container img {
    height: 300px;
  }
}

.gym_wrapper {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
  background: #f5f5f5;
}

.gym_container {
  max-width: 73.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.gym_content_container {
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
}

@media (max-width: 1024px) {
  .gym_content_container {
    flex-direction: row;
    gap: 6.8125rem;
  }
}

.gym_content_text {
  flex-shrink: 0;
}

@media (min-width: 1024px) {
  .gym_content_text {
    max-width: 22.5rem;
  }
}

.gym_text_h2 {
  color:black;
  font-size: 2.3125rem;
  line-height: 3.25rem;
  text-align: center;
  margin-bottom: 0;
  font-weight: 500;
}

@media (min-width: 1024px) {
  .gym_text_h2 {
    text-align: left;
    font-size: 3rem;
    line-height: 3.75rem;
    margin-bottom: 1rem;
  }
}

.gym_text_p {
  color: var(--text-tertiary);
  font-size: 1.125rem;
  line-height: 2rem;
  margin-bottom: 1rem;
}


.gym_content_image_card {
  position: relative;
  max-width: 51.25rem;
  width: 100vw;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}

@media (min-width: 1024px) {
  .gym_content_image_card {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    order: -1; 
  }
}
.swiper-horizontal {
  touch-action: pan-y;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transform: translate3d(0px, 0px, 0px);
  transition-delay: 0ms;
  box-sizing: initial;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}

.swiper-android .swiper-slide,
.swiper-ios .swiper-slide,
.swiper-wrapper {
  transform: translateZ(0);
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}

.card_gym_img {
  height: 16.875rem;
  border-radius: 0.625rem;
  object-fit: cover;
  max-width: 100%;
}

@media (min-width: 1024px) {
  .card_gym_img {
    height: 31rem;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00ff00; 
  border-radius: 50%;
  padding: 10px;
  z-index: 10;
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-prev svg,
.swiper-button-next svg {
  fill: #fff;
}

.swiper-button-disabled {
  background-color: #cccccc;
  cursor: not-allowed; 
  opacity: 0.5; 
}

.swiper-button-disable svg {
  fill: #777777;
}

@media (max-width: 1024px) {
  .gym_wrapper {
    padding-top: 0.5rem;
  }
  
  .gym_content_container {
    flex-direction: column; 
    align-items: center; 
    gap: 0.8125rem;
  }

  .gym_content_text {
    order: 1; 
    margin-bottom: 1rem; 
  }

  .gym_content_image_card {
    order: 2; 
    width: 100%; 
    margin: 0; 
  }

  .gym_text_h2 {
    font-size: 1.5rem; 
    text-align: center; 
  }

  .gym_text_p {
    font-size: 1rem;
    text-align: center;
  }
}
