.carousel-slide {
  position: relative;
  height: 600px;
}

.carousel-slide img,
.carousel-slide video {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.carousel .slick-dots {
  position: absolute;
  bottom: 20px;
  display: flex !important;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.carousel .slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.carousel .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 12px; 
  height: 12px; 
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  background-color: transparent;
  outline: none;
  position: relative;
}

.carousel .slick-dots li button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px; 
  height: 8px; 
  background-color: transparent;
  border: 2px solid #ffffff;
  border-radius: 50%;
  z-index: 1;
}

.carousel .slick-dots li.slick-active button:before {
  background-color: #7cfc00; 
  width: 16px; 
  height: 8px; 
  border-radius: 10px;
  border: none;
}

.custom-arrow {
  width: 40px; 
  height: 40px; 
  background-color: #7cfc00;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
}

.custom-prev {
  left: 20px; 
}

.custom-next {
  right: 20px; 
}

.custom-arrow:before {
  color: #000; 
  font-size: 20px; 
}

.custom-arrow:after {
  content: "";
  position: absolute;
  width: 30px; 
  height: 30px; 
  border: 2px solid #ffffff; 
  clip-path: inset(10px 10px 10px 10px); 
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3; 
  border-top: 5px solid #3498db; 
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
