body {
  margin: 0;
  padding: 0;
  padding-top: 5rem;
  height: 100%;
  font-family: "Geometria", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Geometria", source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #e0f0e0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #4CAF50;
  border-radius: 10px;
  font-family: "Geometria", sans-serif;
}

::-webkit-scrollbar-thumb:hover {
  background: #388E3C;
  font-family: "Geometria", sans-serif;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #4CAF50 #e0f0e0;
  font-family: "Geometria", sans-serif;
}
