/* Estilos para el modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px; /* Ancho máximo del modal */
    max-height: 80vh; /* Máxima altura del modal */
    overflow-y: auto; /* Añadir scroll si el contenido excede la altura */
    display: flex;
    flex-direction: column;
}

/* Estilos para los campos */
.fieldContainer {
    margin-bottom: 20px; /* Espaciado entre los campos */
    margin-top: 10px;
    border: 1px solid #ccc; /* Borde alrededor de los campos */
    border-radius: 4px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
}

/* Estilos para los campos expandibles */
.expandableField {
    cursor: pointer;
    display: flex;
    flex-direction: column; /* Cambiado para apilar verticalmente */
}

.fieldHeader {
    display: flex;
    align-items: center;
    width: 95%;
    padding: 10px; /* Espaciado interno para el encabezado */
    border-bottom: 1px solid #ccc; /* Separador entre el encabezado y el contenido */
}

/* Estilos para los encabezados */
h2 {
    margin-bottom: 20px; /* Espacio inferior para el título del modal */
    text-align: center; /* Centra el título */
}

h3 {
    margin-bottom: 10px; /* Espacio inferior para el subtítulo */
}

/* Estilos para los campos de entrada */
.inputField,
textarea {
    width: 90%; /* Ancho completo */
    border: none; /* Sin borde */
    outline: none; /* Sin contorno */
    padding: 8px; /* Espaciado interno */
    font-size: 16px; /* Tamaño de fuente */
    border-radius: 4px; /* Bordes redondeados */
}

textarea {
    resize: vertical; /* Permite cambiar el tamaño verticalmente */
}

.imagePreview {
    margin-top: 10px; /* Space above the image */
    max-width: 100%; /* Ensure it doesn't exceed the container width */
    height: auto; /* Maintain aspect ratio */
    border: 1px solid #ccc; /* Optional border */
    border-radius: 4px; /* Rounded corners */
}

.modal_buttons_container {
    display: flex;
    justify-content: center; /* Centra los botones horizontalmente */
    align-items: center; /* Alinea los botones verticalmente */
    gap: 20px; /* Espacio entre los botones */
    margin-top: 20px; /* Margen superior para separar de otros elementos */
  }
  
  .button_modal_1, .button_modal_2 {
    padding: 10px 20px; /* Tamaño del botón */
    border: none;
    border-radius: 5px; /* Bordes redondeados */
    font-size: 16px; /* Tamaño de la fuente */
    cursor: pointer;
  }
  
  .button_modal_1 {
    background-color: #4caf50; /* Verde para el botón de Crear/Actualizar */
    color: white;
  }
  
  .button_modal_2 {
    background-color: #f44336; /* Rojo para el botón de Cancelar */
    color: white;
  }
  
  .button_modal_1:hover {
    background-color: #45a049; /* Efecto hover para Crear/Actualizar */
  }
  
  .button_modal_2:hover {
    background-color: #e53935; /* Efecto hover para Cancelar */
  }
  

