.courses_template {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  line-height: 1.4;
}

.courses__list {
  padding: 0 30px;
  list-style: none;
  margin: 107px auto 0;
  max-width: 1470px;
}

.courses__image__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
}

.courses_middle {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
}

.courses__image-content__img {
  flex: 1;
  position: relative;
  height: 500px;
  min-height: 460px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
}

.courses__image-content__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.courses__text__content {
  flex: 1;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  max-height: 500px; /* Max height to avoid overflow */
  overflow: auto; /* Add scroll if content exceeds max height */
}

.courses__text__content h2 {
  letter-spacing: 4px;
  font-size: 2.4rem;
  font-weight: 500;
}

.courses__text__content h3 {
  font-size: 1.2rem;
  color: #757575;
  font-weight: 100;
}

.courses__text__content h2,
.courses__text__content h3 {
  margin: 0;
}

.description_content {
  margin-top: 20px;
  width: 100%;
}

.description_content p {
  font-weight: 100;
}

.icon {
  vertical-align: middle;
  margin-right: 5px;
}

.color-blue {
  color: green;
}

.course_button {
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.button_link {
  display: block;
  padding: 10px 20px;
  background-color: #0000ee;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.button_link:hover {
  background-color: green;
}

/* Responsive Styles */

@media (max-width: 1024px) {
  .course_button {
    display: flex;
    justify-content: center;
    align-items:center;
  }
  .button_link button {
    padding: 6px 20px;
  }
}

@media (max-width: 768px) {
  .courses_middle {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align items */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); /* Add shadow effect */
    border-radius: 15px; /* Add border radius */
    overflow: hidden; /* Ensure rounded corners */
  }

  .courses__image-content__img {
    width: 100%; /* Make image take full width */
    height: auto; /* Maintain aspect ratio */
    min-height: auto; /* Remove min-height */
    position: relative;
    border-top-left-radius: 15px; /* Add border radius to top corners */
    border-top-right-radius: 15px;
  }

  .courses__image-content__img img {
    position: relative;
  }

  .courses__text__content {
    width: 100%; /* Make text container take full width */
    margin-left: 0; /* Remove left margin */
    margin-top: 20px; /* Add top margin for spacing */
    text-align: center; /* Center align text */
    padding: 20px; /* Add padding */
    background-color: white; /* Set background color */
    border-bottom-left-radius: 15px; /* Add border radius to bottom corners */
    border-bottom-right-radius: 15px;
    overflow: auto; /* Add scroll if needed */
  }

  .courses__text__content h2 {
    font-size: 2rem; /* Adjust heading size */
  }

  .courses__text__content h3 {
    font-size: 1rem; /* Adjust subheading size */
  }

  .description_content {
    font-size: 0.9rem; /* Adjust text size */
  }

  .course_button button {
    width: 90%; /* Makes the button take up the full width of its container */
  }
}
