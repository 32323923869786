.mn_header {
    background: #051224;
    color: #fff;
    position: fixed;
    width: 100%;
    z-index: 500;
    top: 0;
    box-sizing: border-box; 
}

.mn_header-primary {
    background: #051226;
    min-height: 5rem;
    display: flex;
    align-items: center;
    box-sizing: border-box; 
}

.mn_header-primary .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    max-width: 1176px; 
    margin: 0 auto; 
    padding: 0 24px;
    box-sizing: border-box; 
}

.wrapper {
    width: 100%;
    max-width: 1176px; 
    margin: 0 auto; 
    padding: 0 24px;
    box-sizing: border-box; 
}

.mn_header-primary .wrapper .section {
    display: flex;
    align-items: center;
    width: 100%;
}

.mn_header-primary .navigation {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.mn_header-primary .navigation > ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.mn_header-primary .navigation > ul li {
    list-style: none;
    margin-right: .25rem;
    border-bottom: .125rem solid transparent;
    padding: 0 0 .5em;
    position: relative;
}

.mn_header-primary .navigation>ul li.active {
    border-bottom: .125rem solid #121fff;
}

.mn_header-primary .navigation > ul li a {
    font-size: 1rem;
    line-height: 1.5em;
    text-decoration: none;
    color: #9ba0a7;
}

.mn_header-primary .navigation > ul li a:hover {
    color: white;
}

.mn_header-primary .navigation>ul li.active a {
    color: #fff;
}

.logo {
    display: flex;
    align-items: center;
    margin-right: auto; 
}

.logo-image {
    width: 100%;
    max-height: 4em;
}

.hamburger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-left: auto;
}

.hamburger-menu span {
    height: 3px;
    width: 25px;
    background: white;
    margin: 4px;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .mn_header-primary .navigation {
        display: none;
    }

    .hamburger-menu {
        display: flex;
    }

    .menu-active .mn_header-primary .navigation {
        display: flex;
        position: fixed;
        top: 5rem;
        left: 0;
        background: #051224;
        width: 100%;
        height: 100vh;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 499;
    }

    .menu-active .mn_header-primary .navigation > ul {
        flex-direction: column;
        gap: 2rem;
    }

    .menu-active .mn_header-primary .navigation > ul li {
        margin-right: 0;
        border-bottom: none;
    }

    .menu-active .mn_header-primary .navigation > ul li a {
        font-size: 1.5rem;
    }

    .logo {
       width: 50px;
    }
}
