.courses_section {
  position: relative;
  background-color: white;
}

.courses_section h2 {
  color: black;
  font-size: 4rem;
  margin: 47px auto 20px;
  max-width: 1220px;
  padding: 0 30px;
  text-align: center;
}

.container {
  display: flex;
  overflow-x: auto;
  overflow: hidden;
  padding: 20px;
}

.card {
  position: relative;
  overflow-x: auto;
  flex: 0 0 25%;
  margin-right: 20px;
  background-color:#051224;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.card img {
  width: 371px;
  height: 500px;
  display: block;
}

.cardContent {
  padding: 20px;
}

.cardContent h2 {
  margin: 0;
  font-size: 24px;
  color: #fff;
}

.cardContent p {
  margin: 5px 0;
  font-size: 16px;
  color: #aaa;
}

.rank {
  display: inline-block;
  margin-left: 2px;
  padding: 5px 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .card img {
    width: 371px;
    height: 300px;
    display: block;
  }

  .courses_section > h2 {
    font-size: 2rem;
  }
}

@media (max-width: 425px) and (min-width: 320px) {
  .card img {
    width: 400px;
    height: 290px;
    display: block;
  }
  
  .courses_section h2 {
    font-size: 1.5rem;
  }
}
