.ranking_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 46vh;
  overflow-x: auto; 
}

.ranking_container table {
  width: 100%; 
  margin-bottom: 30px;
  border-collapse: collapse;
  table-layout: fixed;
}

.ranking_container th, 
.ranking_container td {
  text-align: center;
  padding: 8px; 
  border-bottom: 1px solid #ddd;
  word-wrap: break-word; 
}

.ranking_container th {
  background-color: #051224;
  color: white;
}

.ranking_container tr:nth-child(even) {
  background-color: #f2f2f2;
}

.ranking_container tr:first-child:hover {
  background-color: #ffd700; 
}

.ranking_container tr:not(:first-child):hover {
  background-color: #e0e0e0; 
}

.player-info {
  display: flex;
  align-items: center;
  overflow-x: auto; 
  max-width: 100%; 
  white-space: nowrap; 
}

.player-info .icon-container {
  min-width: 40px; 
  max-width: 40px;
  display: flex;
  justify-content: center; 
}

.player-info span {
  margin-left: 10px;
  font-size: 14px; 
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; 
}

@media (max-width: 600px) {
  th, td {
    font-size: 12px;
  }
  
  .player-info {
    font-size: 12px;
  }
  
  .player-info .icon-container {
    min-width: 30px; 
    max-width: 30px;
  }
}

.player-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%; 
  object-fit: cover; 
  border: 2px solid #051224; 
}

.player-icon {
  font-size: 40px;
  color: #051224;
}

.player-info {
  display: flex;
  align-items: center;
  max-width: 100%;
  white-space: nowrap;
}

.player-info span {
  margin-left: 10px;
  font-size: 14px;
}

@media (max-width: 600px) {
  .player-photo, .player-icon {
    width: 30px;
    height: 30px;
    font-size: 30px;
  }
  
  .player-info span {
    font-size: 12px;
  }
}
