.block {
  display: flex;
  margin-top: -4px;
  margin-bottom: 11px;
  width: 100%;
  height: 150px;
  justify-content: center;
  background: white;
}

.text {
  font-size: 4rem;
}

.block_find {
  display: flex;
  margin-top: -63px;
  margin-bottom: 11px;
  width: 100%;
  height: 176px;
  justify-content: center;
  align-items: center;
  background: white;
}

@media (max-width: 768px) {
  .text {
    font-size: 40px;
  }
  .block {
    height: 60px;
  }
}

@media (max-width: 425px)  and (min-width: 320px) {
  .text {
    line-height: 1.2;
    font-weight: 600;
    font-size: 1.5rem;
  }
  .block {
    height: 33px;
  }
  .wrapper .about_title_mobile {
    display: none;
  }
}

.wrapper {
  width: 100%;
  max-width: 1176px;
  margin: 0 auto;
  padding: 0 24px;
}

.wrapper iframe {
  width: 100%; 
  height: 450px; 
  border: 0; 
}

.about_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about_image_container {
  position: relative;
  min-width: 673px;
  max-width: 673px;
  height: 536px;
  overflow: hidden;
  border-radius: 8px;
}

.about_container img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left;
}

.about_video {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left;
}

.about_section_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 113px;
  margin-left: 70px;
  max-width: 430px;
}

.about_container h2 {
  font-size: 2rem;
  line-height: 1.2;
  margin: 0 0 25px;
  font-weight: 600;
}

.about_container p {
  font-size: 18px;
  line-height: 1.9;
  font-weight: 500;
}

.button_text {
  width: 100%;
}

.button_text .btn {
  padding: 13px 25px;
  margin-top: 30px;
  font-size: 1.067rem;
  border-width: 1px;
  border-radius: 4px;
  font-weight: 400;
  color: azure;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
}

.button_text .btn--primary {
  background-color: #0000ee;
}

.about_title_mobile {
  display: none;
}

@media (max-width: 1200px) {
  .about_image_container {
    min-width: initial;
    width: 100%;
  }
  .about_container img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
  }
}

@media (max-width: 900px) {
  .about_title_mobile {
    display: flex;
    font-size: 2rem;
    line-height: 1.2;
    margin: 0 0 25px;
    font-weight: 600;
  }

  .about_container {
    flex-direction: column;
    background-color: #ffffff;
  }

  .about_image_container {
    max-width: initial;
    height: 270px;
    border-radius: 8px 8px 0 0;
  }

  .about_container img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: left;
  }

  .about_section_text {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    padding: 20px 20px 30px;
    margin: 0;
    max-width: initial;
    border-radius: 0 0 8px 8px;
  }

  .about_section_text h2 {
    display: none;
  }

  .about_section_text p {
    line-height: 1.6;
    font-weight: 300;
    font-size: 1rem;
  }

  .button_text {
    display: flex;
    margin-top: 1px;
    font-size: 1.333rem;
    width: 100%;
  }

  .button_text .btn--primary {
    text-align: center;
    font-size: 10px;
  }
}



