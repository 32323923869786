.footer {
  background-color: #051224; /* Fondo oscuro para el footer */
  color: #fff; /* Texto blanco */
  padding: 2rem; /* Espaciado alrededor del contenido */
  box-sizing: border-box; /* Incluir padding y borde en el cálculo del ancho total */
  width: 100%; /* Asegurar que el footer ocupe todo el ancho */
  position: relative; /* Asegurar que el footer se posicione correctamente */
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinear los elementos al inicio (izquierda) */
  max-width: 1176px; /* Alinear el contenido del footer con el contenido del header */
  margin: 0 auto; /* Centrar el contenido dentro del footer */
  box-sizing: border-box; /* Incluir padding y borde en el cálculo del ancho total */
}

.footer-section {
  margin-bottom: 1rem; /* Espaciado entre secciones */
}

.footer-section.sponsors {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinear los logotipos de patrocinadores a la izquierda */
}

.sponsors-logos {
  display: flex;
  gap: 1rem; /* Espacio entre los logotipos de los patrocinadores */
  flex-wrap: nowrap; /* Evitar el ajuste de línea en los logotipos */
  justify-content: flex-start; /* Alinear los logotipos a la izquierda */
}

.sponsors-logos img {
  height: 80px; /* Altura fija para los logotipos de los patrocinadores */
  width: auto; /* Mantener la proporción de la imagen */
}

.footer-section.contact p {
  margin: 0; /* Eliminar el margen predeterminado del párrafo */
}

.footer-section.social-media {
  display: flex;
  gap: 1rem; /* Espacio entre los íconos de redes sociales */
  align-items: center; /* Alinear los íconos verticalmente en el centro */
  white-space: nowrap; /* Evitar el ajuste de línea en los íconos de redes sociales */
}

.social-icon {
  color: #fff; /* Color blanco para los íconos */
  text-decoration: none; /* Eliminar subrayado de los enlaces */
}

.social-icon img {
  width: 30px; /* Tamaño de los íconos de redes sociales */
  height: auto; /* Mantener la proporción de los íconos */
}

.footer-section.academy-logo img {
  width: 70px; /* Tamaño del logo de la academia */
  height: auto; /* Mantener la proporción del logo */
}

/* Asegurar que el footer se vea bien en pantallas pequeñas */
@media (max-width: 768px) {
  .footer-section.sponsors {
    flex-direction: column;
    align-items: flex-start; /* Alinear los logotipos a la izquierda en pantallas pequeñas */
  }

  .sponsors-logos {
    flex-direction: column;
    align-items: flex-start; /* Alinear los logotipos a la izquierda en pantallas pequeñas */
  }

  .footer-section.social-media {
    flex-direction: row; /* Alinear los íconos en una fila */
    align-items: center; /* Alinear los íconos verticalmente en el centro */
    overflow-x: auto; /* Permitir el desplazamiento horizontal si es necesario */
  }
}
